@use "@/styles/constants/devices.scss" as *;
@import "@/components/sharedStyles/SharedContent.module.scss";

.cardListContainer {
  margin-bottom: 16px;
  background-color: var(--contentBackground);

  @media only screen and (min-width: $tablet) {
    border-radius: 8px;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
}

.iconContainer {
  margin: -16px 0px;
  margin-right: 8px;
  & * {
    color: var(--cardWrapper-highlight);
  }
}

.headerChildrenContainer {
  display: flex;
  align-items: center;
  min-height: 24px;
  min-width: 24px;
  position: relative;

  @media only screen and (min-width: $laptop) {
    min-height: 28px;
    min-width: 28px;
  }
}

.flexBreak {
  flex-basis: 100%;
  height: 0;
}

.subheader {
  margin: 0;
  font-size: 13px;
  color: var(--cardWrapper-highlight);
}

.date {
  color: var(--contentHeaderFontColor);
  margin-left: 0.5rem;
}
